export default [
    {
        src: '/landing/companies/KPMG.webp',
        alt: 'KPMG'
    },
    {
        src: '/landing/companies/SEC.webp',
        alt: 'Saudi Electricity Company'
    },
    {
        src: '/landing/companies/AC.webp',
        alt: 'Albilad Capital'
    },
    {
        src: '/landing/companies/SNB.webp',
        alt: 'Saudi National Bank'
    },
    {
        src: '/landing/companies/SAB.webp',
        alt: 'Saudi Awwal Bank'
    },
    {
        src: '/landing/companies/ARAMCO.webp',
        alt: 'ARAMCO'
    },
    {
        src: '/landing/companies/ABDLJ.webp',
        alt: 'Abdullatif Jameel'
    },
    {
        src: '/landing/companies/TAW.webp',
        alt: 'Tawuniya'
    },
    {
        src: '/landing/companies/RAJCAP.webp',
        alt: 'AlRajhi Capital'
    },
    {
        src: '/landing/companies/MOBILY.webp',
        alt: 'Mobily'
    },
    {
        src: '/landing/companies/NISSAN.webp',
        alt: 'Nissan'
    },
    {
        src: '/landing/companies/NOVA.webp',
        alt: 'NOVA'
    },
    {
        src: '/landing/companies/WALAA.webp',
        alt: 'Walaa'
    },
    {
        src: '/landing/companies/MEDGULF.webp',
        alt: 'MedGulf'
    },
    {
        src: '/landing/companies/RER.webp',
        alt: 'Real Estate Records'
    },
    {
        src: '/landing/companies/AJIL.webp',
        alt: 'Ajil Financial Services Company'
    }
]
